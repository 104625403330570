<template>
  <v-container fluid>
    <v-row>
      <v-col class="mt-10">
        <v-btn
          color="primary"
          text
          @click="goBack()"
          class="text-none"
          :disabled="loading"
        >
          <v-icon>mdi-arrow-left</v-icon>

          <span class="font-16 ml-1"> Volver a ofertas disponibles </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="px-6">
      <v-col>
        <span class="font-24 bold">Carrito</span>
      </v-col>
    </v-row>
    <v-row class="px-4" style="max-width: 1700px">
      <v-col cols="12" md="8">
        <template v-if="!loading">
          <div
            v-for="(item, i) in shoppingCart?.ofertas ?? []"
            :key="i"
            style="max-width: 850px"
          >
            <Cart
              :Item="item"
              @ver-mas="(selected = $event), (dialog = true)"
              :cuotas="item?.objeto_vigencia?.meses || vigencia"
              :Propuesta="shoppingCart.propuesta"
              :descuento="shoppingCart?.canal?.aplica_descuento ?? false"
              :info="info"
            />
          </div>
        </template>
        <template v-else>
          <v-skeleton-loader
            v-for="index in 3"
            :key="index"
            class="my-3"
            height="90"
            type="card"
            style="max-width: 736px"
          />
        </template>
      </v-col>
      <v-col cols="12" md="4">
        <div style="max-width: 354px">
          <Resume
            :Cart="shoppingCart?.ofertas"
            @purchase="goToContract()"
            :loading="loadingContract"
            v-if="!loading"
          />
          <v-skeleton-loader v-else height="250" type="card" />
        </div>
      </v-col>
    </v-row>

    <!-- <div
          class="global-adicionalesProducts"
          :style="{ background: bgColor }"
        >
          <p class="global-title20 mt-5 ml-4">Productos adicionales</p>

          <v-flex v-if="loading" class="d-flex justify-center">
            <v-skeleton-loader
              v-for="index in 3"
              :key="index"
              class="ma-3"
              width="250"
              height="150px"
              type="card"
            />
          </v-flex>

          <v-card
            v-if="!loading"
            class="mt-4 d-flex flex-wrap transparent"
            flat
          >
            <v-slide-group show-arrows width="100%">
              <v-slide-item
                class="mx-2"
                v-for="(prodItem, i) in ProdutosAdicionales"
                :key="i"
              >
                <v-flex>
                  <SecondProduct :Items="prodItem" :VerMas="VerMas" />
                </v-flex>
              </v-slide-item>
            </v-slide-group>
          </v-card>
        </div> -->

    <v-flex lg4 xl3 class="d-flex ml-xl-8 align-center flex-column">
      <div class="paymentflex">
        <!-- <PaymentCart
            :TotalUF="TotalUF * vigencia"
            :TotalPrice="TotalPrice * vigencia"
            :ProductCuotas="vigencia"
          /> -->
        <Modal
          :open="dialog"
          :selected="selected"
          @close="dialog = false"
          @download="GenerateQuot($event, nroCotizacion)"
          @share="sendQuot($event, nroCotizacion)"
          :loadingquot="loadQuot"
          :load-share="loadShare"
        />
      </div>
    </v-flex>
  </v-container>
</template>
<script>
import Cart from "@/components/Cards/ShoppingCart/Cart-card";
// import PaymentCart from "@/components/Cards/Payment-card";
import Resume from "@/components/Cards/ShoppingCart/Resume-buy-card";
// import SecondProduct from "@/components/Cards/Second-Product-card";
import Modal from "@/components/Modal/Modal-Detail";
import axios from "axios";

import { GenShare } from "@/Mixins/GenAndShareQuotMixin.js";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "CarritoDeCompra",
  mixins: [GenShare],
  props: {
    propuesta: {
      type: [String, Number],
      default: null,
      description: "Numero de la propuesta (:propuesta en el header)",
    },
    info: {
      type: Object,
      default: () => {},
      description: "Cotizacion de la propuesta",
    },
  },
  components: {
    Cart,
    // PaymentCart,
    Resume,
    // SecondProduct,
    Modal,
  },
  data() {
    return {
      dialog: false,
      selected: null,
      // ProdutosAdicionales: [],
      loading: false,
      loadingContract: false,
      defaultTarifa: 12,
    };
  },
  computed: {
    ...mapGetters("Proposal", ["tarifa", "shoppingCart"]),

    nroCotizacion() {
      return this.shoppingCart?.propuesta?.numero_cotizacion;
    },

    vigencia() {
      return this.tarifa?.objeto_vigencia?.meses || this.defaultTarifa;
    },
  },
  created() {
    window.scrollTo(0, 0);
    this.setup();
  },
  methods: {
    ...mapActions("Proposal", ["getResumeCart"]),

    goBack() {
      const id = this.nroCotizacion;
      if (id) {
        this.$router.push({
          name: "quotationOffers",
          params: { id },
        });
      } else {
        this.$router.push({
          name: "dashboard",
        });
      }
    },
    setup() {
      this.loading = true;
      this.getResumeCart(this.propuesta).finally(() => {
        this.loading = false;
      });

      // const res = await axios({
      //   url: `carrito/${this.Propuesta.numero_propuesta}/adicionales`,
      //   method: "GET",
      // });

      // this.ProdutosAdicionales = res.data.data.planes_adicionales;
    },

    goToContract() {
      if (this.nroCotizacion) {
        const path = `/cotizaciones/${this.nroCotizacion}/actualizar-estado`,
          formData = {
            estado: "FAL",
            estado_descripcion: "Falta Información",
          };
        this.loadingContract = true;
        axios
          .put(path, formData)
          .then(() => {
            this.$router.push({
              name: "Contract",
              params: {
                id: this.shoppingCart?.propuesta?.numero_propuesta,
              },
              query: {
                paso: this.shoppingCart?.propuesta?.step ?? 1,
              },
            });
          })
          .catch(() => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: "Ha ocurrido un error al intentar actualizar el estado de la propuesta.",
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => {
            this.loadingContract = false;
          });
      }
    },
  },
};
</script>
