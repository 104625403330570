<template>
  <v-dialog persistent v-model="open" width="950" attach="#main-container">
    <v-card class="br-6 light">
      <v-card-text>
        <v-row class="pl-10 pt-10">
          <v-col class="d-flex w-100 justify-space-between align-center">
            <span class="h5 semiBold w-100"
              >Solicitud de descuento en revisión</span
            >
            <v-btn icon @click="toggle" small class="ml-3">
              <Icon name="close" size="15px" />
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="px-10">
          <v-col cols="12">
            <div class="d-flex box pa-4 mb-6">
              <Icon name="check_circle" size="40px" class="icon-success" />
              <div class="ml-4">
                Su solicitud de <b> 2% de descuento </b>para el plan
                <b>M. Particular sin exclusivdad UF 0</b> ha sido enviada
                exitosamente y se encuentra en proceso de revisión. <br /><br />
                <span class="mt-4">
                  Recuerda que se te <b>enviará un mensaje</b> para confirmar si
                  la <b>solicitud</b> es <b>aprobada o rechazada.</b>
                </span>
              </div>
            </div>

            <span class="p semiBold title-color">Resumen de la operación</span>
            <div class="d-flex border-table mt-3">
              <div class="d-flex w-50">
                <div class="d-flex flex-column bg-title px-4 py-3">
                  <span class="p semiBold py-2">Datos de la cotización</span>
                  <span class="p semiBold py-2">Datos de la cotización</span>
                  <span class="p semiBold py-2">Datos de la cotización</span>
                </div>
                <div class="d-flex flex-column px-4 py-3">
                  <span class="p py-2">Datos de la cotización</span>
                  <span class="p py-2">Datos de la cotización</span>
                  <span class="p py-2">Datos de la cotización</span>
                </div>
              </div>
              <div class="d-flex w-50">
                <div class="d-flex flex-column bg-title px-4 py-3">
                  <span class="p semiBold py-2">Datos de la cotización</span>
                  <span class="p semiBold py-2">Datos de la cotización</span>
                  <span class="p semiBold py-2">Datos de la cotización</span>
                </div>
                <div class="d-flex flex-column px-4 py-3">
                  <span class="p py-2">Datos de la cotización</span>
                  <span class="p py-2">Datos de la cotización</span>
                  <span class="p py-2">Datos de la cotización</span>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-10">
          <v-col cols="12">
            <div class="d-flex border-table mt-3">
              <div class="d-flex w-100">
                <div class="d-flex flex-column bg-title px-4 py-3">
                  <span class="p semiBold py-2">Datos de la cotización</span>
                  <span class="p semiBold py-2">Datos de la cotización</span>
                  <span class="p semiBold py-2">Datos de la cotización</span>
                  <span class="p semiBold py-2">Datos de la cotización</span>
                </div>
                <div class="d-flex flex-column px-4 py-3">
                  <span class="p py-2">Datos de la cotización</span>
                  <span class="p py-2">Datos de la cotización</span>
                  <span class="p py-2">Datos de la cotización</span>
                  <span class="p py-2">Datos de la cotización</span>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-10">
          <v-col>
            <div class="d-flex align-center">
              <Icon name="update" size="25px" />

              <span class="ml-4">
                Serás redirigido en <b>{{ timerCount }} segundos</b> al inicio
                de la plataforma para que sigas cotizando
              </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="d-flex justify-center">
              <v-btn
                rounded
                @click="toggle"
                color="primary"
                width="180"
                class="text-none ml-4"
                >Entendido</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
// import inputText from "@/components/Inputs/Input";
import Icon from "@/components/Icon/Icon.vue";

export default {
  props: ["toggle"],
  data() {
    return {
      open: true,
      timerCount: 20,
    };
  },
  mounted: {},
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else {
          this.open = false;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  components: {
    Icon,
  },

  methods: {},
};
</script>
<style scoped>
@import "../../assets/CSS/ServiceStyle.css";

.bg-title {
  background-color: #e3eeff !important;
}
.border-table {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.box {
  background-color: #f4f7fc !important;
}
</style>
