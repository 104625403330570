<template>
  <div>
    <v-card class="ma-0 pa-0" flat outlined>
      <div class="d-flex justify-space-between card-content">
        <div class="d-flex">
          <div>
            <v-fade-transition>
              <v-img
                class="global-img"
                :src="Item[logoName]"
                @error="handleLogoError($event, Item)"
                width="80"
                max-height="60"
                contain>
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      indeterminate
                      color="primary"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-fade-transition>
            <div class="text-white badge primary-bg text-center w-80 mt-1">
              {{ Item?.objeto_vigencia?.vigencia_descripcion ?? "" }}
            </div>
          </div>
          <div class="d-flex flex-column my-2 container-titles">
            <span class="bold">{{ Item?.nombre_producto ?? "" }}</span>
            <span class="bold">
              {{ info?.marca_descripcion ?? "" }}
              {{ info?.modelo_descripcion ?? "" }}
              {{ info?.anio ?? "" }}
            </span>
          </div>
        </div>
        <div class="d-flex flex-column my-2 info-container">
          <h6 class="regular primary--text">
            {{ Item?.plan_descripcion ?? "" }}
          </h6>
          <span>
            Monto mensual -
            <span class="font-weight-bold">{{ cuotas }}</span> cuotas de</span
          >
          <span class="bold title-color">
            {{ Item?.moneda_local.simbolo
            }}{{ (Item?.moneda_local?.precio ?? "").toLocaleString("es-CL") }} |
            <span class="primary--text bold">
              {{ Item?.moneda_seguro.simbolo }}
              {{ Item?.moneda_seguro?.precio ?? "" }}
            </span>
          </span>
        </div>
        <div class="d-flex flex-column my-2 align-start container-actions">
          <v-btn
            text
            small
            @click="$emit('ver-mas', { ...Item, ...info })"
            class="text-none">
            <Icon name="visibility" />
            <span class="ml-2 bold primary--text">Ver más</span>
          </v-btn>
          <v-btn
            text
            small
            :loading="deleteloader"
            @click="deleteItemCart(Item)"
            :disabled="principal"
            class="text-none"
            ><Icon name="delete" />
            <span class="ml-2 bold primary--text">Eliminar</span>
          </v-btn>
          <!--      <v-btn
            text
            small
            :loading="deleteloader"
            :disabled="!descuento"
            @click="toggleModal"
            class="text-none"
            ><Icon name="sell" />
            <span class="ml-2 bold primary--text">Solicitar descuento</span>
          </v-btn> -->
        </div>
        <ModalRequestDiscount
          v-if="openModal"
          :toggle="toggleModal"
          @handleSubmit="handleSubmit"
          :data="Propuesta" />
        <ModalRequestDiscountSuccess
          v-if="openSuccessModal"
          :toggle="toggleModalSuccess"
          :data="Propuesta" />
      </div>
    </v-card>
  </div>
</template>

<script>
import { cartItem } from "@/Mixins/CartComponentMixin.js";

import Icon from "@/components/Icon/Icon.vue";
import ModalRequestDiscount from "@/components/Modal/Modal-discount-request.vue";
import ModalRequestDiscountSuccess from "@/components/Modal/Modal-discount-request-success.vue";
import LogoRequestMixin from "@/Mixins/LogoRequestMixin.js";
export default {
  name: "ArticuloDelCarrito",
  mixins: [cartItem, LogoRequestMixin],
  props: {
    Item: {
      type: Object,
      default: null,
    },
    info: {
      type: Object,
      default: null,
    },
    Propuesta: {
      type: Object,
      default: () => {},
    },
    VerMas: {
      type: Function,
      default(itms) {
        return itms;
      },
    },
    cuotas: {
      type: Number,
      default: 0,
      description: "Numero de cuotas a mostrar",
    },
    principal: {
      type: Boolean,
      default: true,
    },
    descuento: {
      type: Boolean,
      default: false,
      description: "Check si tiene descuento o no el canal",
    },
  },
  components: {
    Icon,
    ModalRequestDiscount,
    ModalRequestDiscountSuccess,
  },
  data() {
    return {
      deleteloader: null,
      id: this.$route.params.id,
      openModal: false,
      openSuccessModal: false,
    };
  },
  methods: {
    deleteItemCart(Item) {
      this.deleteItem(Item);
    },
    toggleModal() {
      this.openModal = !this.openModal;
    },
    toggleModalSuccess() {
      this.openSuccessModal = !this.openSuccessModal;
    },
    handleSubmit() {
      this.openModal = false;
      this.openSuccessModal = true;
    },
  },
};
</script>

<style scoped>
.badge {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.container-titles {
  justify-content: space-evenly;
  font-size: 15px;
  max-width: 220px;
}
.info-container {
  justify-content: space-between;
}
.container-actions {
  justify-content: space-evenly;
}
.card-content {
  padding-right: 4%;
}
</style>
