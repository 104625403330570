<template>
  <div>
    <v-card class="light p-10" flat outlined>
      <v-card-title class="pt-0">
        <div class="title-buy-card">
          <span class="font-18 bold">Resumen de compra</span>
        </div>
      </v-card-title>
      <v-card-text>
        <div class="d-flex flex-column">
          <div
            class="d-flex justify-space-between"
            v-for="(item, i) in Cart"
            :key="i">
            <span class="regular text-capitalize">{{
              item.plan_descripcion
            }}</span>
            <span class="font-16">
              {{ Cart[0].moneda_local.simbolo }}
              {{ item.moneda_local.precio.toLocaleString("es-CL") }} |
              {{ Cart[0].moneda_seguro.simbolo
              }}{{ item.moneda_seguro.precio }}</span
            >
          </div>
          <div class="d-flex justify-space-between mt-4">
            <span class="bold">Total</span>
            <span>
              <span class="bold font-16">
                {{ Cart[0].moneda_local.simbolo
                }}{{ TotalPrice.toLocaleString("es-CL") }}</span
              >
              |
              <span class="primary--text bold"
                >{{ Cart[0].moneda_seguro.simbolo }}{{ TotalUF }}</span
              >
            </span>
          </div>

          <div class="d-flex justify-center mt-8">
            <v-btn
              color="primary"
              rounded
              :loading="loading"
              @click="$emit('purchase')"
              class="text-none px-5 continue-button"
              name="boton_continuar"
              >Continuar contratación</v-btn
            >
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    Cart: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    TotalPrice() {
      const Money = this.Cart;
      return Money.reduce((acc, value) => {
        return acc + value.moneda_local.precio;
      }, 0);
    },
    TotalUF() {
      const Money = this.Cart;
      return Money.reduce((acc, value) => {
        return acc + value.moneda_seguro.precio;
      }, 0);
    },
  },
};
</script>
<style scoped>
.title-buy-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 30px !important;
  color: rgba(0, 0, 0, 0.6);
}
.continue-button {
  height: 40px !important;
}
</style>
