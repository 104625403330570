<template>
  <v-dialog persistent v-model="open" width="950" attach="#main-container">
    <v-card class="br-6 light">
      <v-card-text>
        <v-row class="pl-10 pt-10">
          <v-col class="d-flex w-100 justify-space-between align-center">
            <span class="h5 semiBold w-100 bg-title pa-2"
              >Solicitar descuento</span
            >
            <v-btn icon @click="toggle" small class="ml-3">
              <Icon name="close" size="15px" />
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="px-10">
          <v-col cols="12">
            <span class="p semiBold title-color">Datos de la cotización</span>
            <div class="d-flex border-table mt-3">
              <div class="d-flex w-50">
                <div class="d-flex flex-column bg-title px-4 py-3">
                  <span class="p semiBold py-2">Cotización N°</span>
                  <span class="p semiBold py-2">Rut</span>
                  <span class="p semiBold py-2">Nombre</span>
                </div>
                <div class="d-flex flex-column px-4 py-3">
                  <span class="p py-2">{{
                    data?.numero_cotizacion ?? ""
                  }}</span>
                  <span class="p py-2">{{
                    data?.numero_documento ?? "" | rutFormat
                  }}</span>
                  <span class="p py-2">{{ data?.nombre_usuario ?? "" }}</span>
                </div>
              </div>
              <div class="d-flex w-50">
                <div class="d-flex flex-column bg-title px-4 py-3">
                  <span class="p semiBold py-2">Plan</span>
                  <span class="p semiBold py-2">Compañía</span>
                  <span class="p semiBold py-2">Cuota</span>
                </div>
                <div class="d-flex flex-column px-4 py-3">
                  <span class="p py-2">{{
                    data.tarifa_seleccionada.nombre_producto
                  }}</span>
                  <span class="p py-2">
                    <v-img
                      :src="getLogo(setLogoData(data?.tarifa_seleccionada))"
                      width="50"
                    />
                  </span>
                  <span class="p py-2">
                    {{
                      data?.tarifa_seleccionada.moneda_local.precio
                        | moneyFormat
                    }}
                    <span class="px-2">|</span
                    >{{
                      (data?.tarifa_seleccionada.moneda_seguro?.precio ||
                        data?.tarifa_seleccionada.prima_mensual_seguro ||
                        "") | UFPrice
                    }}</span
                  >
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-10">
          <v-col cols="12">
            <span class="p semiBold title-color">Datos del vendedor</span>
            <div class="d-flex border-table mt-3">
              <div class="d-flex w-100">
                <div class="d-flex flex-column bg-title px-4 py-3">
                  <span class="p semiBold py-2">Rut</span>
                  <span class="p semiBold py-2">Nombre</span>
                </div>
                <div
                  class="d-flex flex-column px-4 py-3"
                  v-if="resume.vendedor"
                >
                  <span class="p py-2">{{
                    resume.vendedor.vendedor | rutFormat
                  }}</span>
                  <span class="p py-2">{{
                    resume.vendedor.vendedor_descripcion
                  }}</span>
                </div>
                <div v-else class="d-flex flex-column px-4 py-3">
                  <span class="p py-2">
                    <v-skeleton-loader
                      class="mx-auto"
                      width="300"
                      type="text"
                    ></v-skeleton-loader> </span
                  ><span class="p py-2">
                    <v-skeleton-loader
                      class="mx-auto"
                      width="300"
                      type="text"
                    ></v-skeleton-loader>
                  </span>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-10">
          <v-col cols="12" md="6">
            <v-form>
              <LabelComponent text="Descuento a solicitar (%)" />
              <InputField
                :model="form.descuento"
                @input="form.descuento = $event"
                size="long"
                color="#0077c8"
                detail_color="rgba(242, 242, 242, 0.7)"
                height="44"
                @blur="calculate"
              />
            </v-form>
          </v-col>
          <v-col cols="12" md="6">
            <v-form>
              <LabelComponent :text="cargo.label" />
              <v-select
                :items="cargo.answers"
                v-model="form.cargo"
                outlined
                dense
                item-text="descripcion"
                item-value="valor"
                return-object
                append-icon="mdi-chevron-down"
                :loading="!cargoLoaded"
              ></v-select>
            </v-form>
          </v-col>
          <v-col cols="12" md="6">
            <v-form>
              <LabelComponent text="Cuota con descuento incluido UF" />
              <InputField
                :model="form.cuota_uf"
                @input="form.cuota_ud = $event"
                size="long"
                color="#0077c8"
                detail_color="rgba(242, 242, 242, 0.7)"
                height="44"
                readonly="true"
              />
            </v-form>
          </v-col>
          <v-col cols="12" md="6">
            <v-form>
              <LabelComponent :text="motivo.label" />
              <v-select
                :items="motivo.answers"
                v-model="form.motivo"
                outlined
                dense
                item-text="descripcion"
                item-value="valor"
                return-object
                append-icon="mdi-chevron-down"
                :loading="!cargoLoaded"
              ></v-select>
            </v-form>
          </v-col>
          <v-col cols="12" md="6">
            <v-form>
              <LabelComponent text="Cuota con descuento incluido $" />
              <InputField
                :model="form.cuota_peso"
                @input="form.cuota_peso = $event"
                size="long"
                color="#0077c8"
                detail_color="rgba(242, 242, 242, 0.7)"
                height="44"
                readonly="true"
              />
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="d-flex justify-center">
              <v-btn
                rounded
                outlined
                color="primary "
                width="180"
                class="text-none"
                @click="toggle"
                >Cancelar</v-btn
              >
              <v-btn
                rounded
                @click="submit"
                color="primary"
                width="180"
                class="text-none ml-4"
                :loading="loading"
                :disabled="!validForm"
                >Solicitar descuento</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
// import inputText from "@/components/Inputs/Input";
import Icon from "@/components/Icon/Icon.vue";
// import Alert from "@/components/Alert/Alert.vue";
import LabelComponent from "@/components/Inputs/Label.vue";
import { validarEmail } from "@/store/resources/validate.js";
import InputField from "@/components/Inputs/Input.vue";
import { mapActions, mapGetters } from "vuex";

import LogoRequestMixin from "@/Mixins/LogoRequestMixin.js";

export default {
  props: ["toggle", "data"],
  mixins: [LogoRequestMixin],
  data() {
    return {
      open: true,
      validForm: false,
      loading: false,
      cargoLoaded: false,
      resume: {},
      form: {
        descuento: "0",
        cargo: "",
        cuota_uf: "",
        motivo: "",
        cuota_peso: "",
      },
      cargo: {
        label: "Cargo descuento",
        answers: [],
        selected: "",
        rules: [(v) => !!v || "Campo requerido"],
      },
      motivo: {
        label: "Motivo",
        answers: [],
        selected: "",
        rules: [(v) => !!v || "Campo requerido"],
      },
      rules: {
        required: (value) => !!value || "Campo requerido.",
        email: (value) => validarEmail(value) || "Correo invalido.",
      },
    };
  },
  methods: {
    ...mapActions("Discounts", [
      "getResume",
      "getCargosMotivos",
      "createDiscount",
      "calculateDiscount",
    ]),
    async calculate() {
      const discount = await this.calculateDiscount({
        tipo_descuento: "PORCENTUAL",
        monto_descuento: this.form.descuento,
        monto_seguro:
          this.data?.tarifa_seleccionada.moneda_seguro?.precio ||
          this.data?.tarifa_seleccionada.prima_mensual_seguro,
      });
      this.form.cuota_uf = discount.nuevo_monto_seguro.toString();
      this.form.cuota_peso = discount.nuevo_monto_seguro_local.toString();
    },
    async submit() {
      this.loading = true;
      try {
        await this.createDiscount({
          cotizacion: this.data?.numero_cotizacion,
          plan: this.resume.plan.codigo,
          tipo_descuento: "PORCENTUAL",
          monto_descuento: this.form.descuento,
          cargo: this.form.cargo.valor,
          motivo: this.form.motivo.valor,
        });
        this.$emit("handleSubmit");
      } catch (error) {
        console.log("error", error);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    const cm = await this.getCargosMotivos();
    this.cargo.answers = cm.cargos;
    this.motivo.answers = cm.motivos;
    this.cargoLoaded = true;

    this.resume = await this.getResume(this.data?.numero_cotizacion);
  },
  watch: {
    form: {
      handler(val) {
        const validations = [];
        const descuento = this.rules.required(val.descuento);
        const cargo = this.rules.required(val.cargo);
        const motivo = this.rules.required(val.motivo);

        validations.push(descuento, cargo, motivo);
        this.validForm = validations.every(
          (val) => typeof val === "boolean" && val
        );
      },
      deep: true,
    },
  },
  components: {
    Icon,
    LabelComponent,
    InputField,
    // inputText,
    // Alert,
  },
  computed: {
    ...mapGetters("Proposal", ["getProposalData"]),

    cotizacion() {
      return this.getProposalData.cotizacion;
    },
  },
};
</script>
<style scoped>
@import "../../assets/CSS/ServiceStyle.css";

.bg-title {
  background-color: #e3eeff !important;
}
.border-table {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
</style>
